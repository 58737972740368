'use client';
import { useEffect } from 'react';

export const useFontSize = (size: number) => {
	useEffect(() => {
		const variableName = '--fontSize';
		const newFontSize = `var(${variableName}-${size})`;
		document.body.style.setProperty(variableName, newFontSize);
	}, [size]);
};
