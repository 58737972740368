// стили
import style from './AboutModalWin.module.sass'

// хуки
import { useAppSelector } from '@/Hooks/redux'
import { useDispatch } from 'react-redux'
// actions
import { NavMobileSlice } from '@/Redux/Slices/NavMobileSlice/NavMobileSlice'

export const AboutModalWin = () => {
	// redux приколы
	const { modalWindAbout } = useAppSelector((state) => state.NavMobileSlice)
	const dispatch = useDispatch()

	// actions
	const { SetWindowOpen } = NavMobileSlice.actions

	return (
		<>
			{modalWindAbout && (
				<>
					<div className={style.modalSourcesBackground}></div>
					<div className={style.modalSourcesCont}>
						<svg
							onClick={() => dispatch(SetWindowOpen(false))}
							style={{ cursor: 'pointer' }}
							width="14"
							height="14"
							viewBox="0 0 14 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
								fill="#686868"
							></path>
						</svg>
						<div
							style={{ fontSize: '17px' }}
							className={style.modalAboutWrapper}
						>
							<p>
								СМИ2<span>&nbsp;</span>
								<span>—</span> точка сбора самых интересных и
								<span>&nbsp;</span>
								актуальных новостей российских <span>онлайн-медиа</span>
								. <span>«</span>Картина дня
								<span>»</span> формируется автоматически на
								<span>&nbsp;</span>базе популярности материалов у
								<span>&nbsp;</span>читателей, также используются данные
								обменной новостной сети{' '}
								<a
									href="https://smi2.net"
									target="_blank"
								>
									СМИ2.net
								</a>
								. В<span>&nbsp;</span>проекте участвуют отобранные нами
								русскоязычные издания.
							</p>
							<p>
								Вы<span>&nbsp;</span>можете просмотреть ленту, а
								<span>&nbsp;</span>также выбрать конкретные темы и
								<span>&nbsp;</span>получить выборку новостей по
								<span>&nbsp;</span>ним. При клике на
								<span>&nbsp;</span>
								заголовок новости или на<span>&nbsp;</span>
								<span>«</span>читать подробнее<span>»</span> вы
								<span>&nbsp;</span>попадете на<span>&nbsp;</span>
								полнотекстовый материал на<span>&nbsp;</span>сайте
								издания.
							</p>
							<p>
								Агрегатор СМИ2 работает в<span>&nbsp;</span>тесной
								связке с<span>&nbsp;</span>новостной сетью{' '}
								<a
									href="https://smi2.net"
									target="_blank"
								>
									СМИ2.net
								</a>
								. Участие в<span>&nbsp;</span>обоих проектах бесплатно,
								по
								<span>&nbsp;</span>вопросам сотрудничества обращайтесь
								на
								<span>&nbsp;</span>
								<a href="mailto:info@smi2.net">info@smi2.net</a>.
							</p>
							<p>
								Адрес: ул.&nbsp;Покровка, д.&nbsp;18/18, стр.&nbsp;1,
								г.&nbsp;Москва, 101000
								<br />
								Телефон: +7 495 626-43-91
							</p>
						</div>
					</div>
				</>
			)}
		</>
	)
}
